import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import SEO from "../components/seo"
import Section from "../components/Section"
import { Container } from "../components/Container"
import breakpoints from "../styles/breakpoints"
import ContactForm from "../components/ContactForm"
import BlockText from "../components/BlockText"
import SvgBorderTop from "../components/SvgBorderTop"
import ContactCard from "../components/ContactCard"
import localeData from "../locale"
import BreadCrumbs from "../components/BreadCrumbs"

const Title1 = styled.h1`
  text-align: center;
  color: ${props => props.theme.primaryColors.primary};
`

const Title2 = styled.h2`
  margin-top: 120px;
  margin-bottom: 56px;
  text-align: center;
  color: ${props => props.theme.primaryColors.primary};
`

const ContactFormContainer = styled.div`
  margin-top: 40px;
  ${breakpoints.md`
    margin-top: 88px;
  `};
`

const TextCenter = styled.div`
  text-align: center;
`

export const query = graphql`
  {
    sanityContactPage {
      id
      _rawLocaleTitle
      _rawTextBlock1
    }
    allSanityTeam {
      edges {
        node {
          id
          name
          degree
          phone
          email
          image {
            asset {
              url
            }
          }
        }
      }
    }
    sanityCompanyInfo {
      email
    }
  }
`

const Contact = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { _rawLocaleTitle, _rawTextBlock1 } = data.sanityContactPage
  const { allSanityTeam } = data
  const { email } = data.sanityCompanyInfo

  return (
    <>
      <SEO title={_rawLocaleTitle[locale]} />
      {_rawLocaleTitle && <BreadCrumbs pageTitle={_rawLocaleTitle[locale]} locale={locale} />}
      <Section paddingTop paddingBottom>
        <Grid container spacing={0} justify="center">
          <Grid item xs={12} md={6}>
            {_rawLocaleTitle && (
              <Title1 center>{_rawLocaleTitle[locale]}</Title1>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            {_rawTextBlock1 && (
              <TextCenter>
                <BlockText blocks={_rawTextBlock1[locale]} />
              </TextCenter>
            )}
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            <ContactFormContainer>
              <ContactForm locale={locale} companyEmail={email} />
            </ContactFormContainer>
          </Grid>
        </Grid>
      </Section>
      <SvgBorderTop grey>
        <Container grey paddingBottom>
          <Section>
            <Grid container justify="center">
              <Grid item xs={12} md={6}>
                <Title2>{localeData._rawContactTeamTitle[locale]}</Title2>
              </Grid>
            </Grid>
            {allSanityTeam.edges && (
              <Grid container spacing={16} justify="center">
                {allSanityTeam.edges.map(contact => (
                  <Grid item xs={12} md={6} key={contact.node.id}>
                    <ContactCard contact={contact.node} locale={locale} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Section>
        </Container>
      </SvgBorderTop>
    </>
  )
}

export default Contact
