import React from "react"
import styled from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import Reaptcha from "reaptcha"
import axios from "../../utils/axios"
import TextField from "../InputFields/TextField"
import ErrorMessage from "../ErrorMessage"
import checkValidity from "../../utils/checkValidity"
import PrimaryBtn from "../Buttons/PrimaryBtn"
import SuccesBackground from "../UI/SuccesBackground"
import OptionalFormText from "../UI/OptionalFormText"
import Loader from "../Loader"
import localeData from "../../locale"
import Vspacer from "../Vspacer"
import vSpacing from "../../styles/vSpacing"

const InputContainer = styled.div`
  margin-bottom: 32px;
`

class ContactForm extends React.PureComponent {
  state = {
    formData: {
      name: {
        id: "name",
        elementType: "input",
        elementConfig: {
          type: "name",
        },
        value: "",
        validation: {
          required: true,
        },
        error: false,
        errorMessage: "Please fill in your name",
        valid: false,
        touched: false,
      },
      email: {
        id: "email",
        elementType: "input",
        elementConfig: {
          type: "email",
        },
        value: "",
        validation: {
          required: true,
          isEmail: true,
        },
        error: false,
        errorMessage: "Please fill in your valid e-mail address",
        valid: false,
        touched: false,
      },
      phone: {
        id: "phone",
        elementType: "input",
        elementConfig: {
          type: "number",
        },
        validation: {
          required: false,
        },
        value: "",
        touched: false,
      },
      subject: {
        id: "subject",
        elementType: "input",
        elementConfig: {
          type: "subject",
        },
        validation: {
          required: false,
        },
        value: "",
        touched: false,
      },
      message: {
        id: "message",
        elementType: "input",
        elementConfig: {
          type: "message",
        },
        value: "",
        validation: {
          required: true,
        },
        error: false,
        errorMessage: "Please fill in your message",
        valid: false,
        touched: false,
      },
    },
    formIsValid: false,
    reCaptchaPassed: false,
    loading: false,
    succes: false,
    submitFailed: false,
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    const submitFormData = {}
    const { formData } = this.state
    for (const formElement in formData) {
      submitFormData[formElement] = formData[formElement].value
    }

    axios
      .post("/contact-us", submitFormData)
      .then((response) => {
        this.setState({ loading: false, succes: true, submitFailed: false })
        trackCustomEvent({
          category: "Contact Formulier",
          action: "Verstuurd",
          label: `Algemeent contact`,
        })
      })
      .catch((error) => {
        this.setState({ loading: false, submitFailed: true })
      })
  }

  handleReCAPTCHAValid = () => {
    this.setState({
      reCaptchaPassed: true,
    })
  }

  handleChange(e, key) {
    const { formData } = this.state
    const newFormData = {
      ...formData,
    }

    newFormData[key].value = e.target.value
    newFormData[key].valid = checkValidity(
      newFormData[key].value,
      newFormData[key].validation
    )
    newFormData[key].touched = true
    if (newFormData[key].valid) {
      newFormData[key].error = false
    }

    let formIsValid = true
    for (const formElement in newFormData) {
      if (
        newFormData[formElement].validation.required &&
        !newFormData[formElement].valid
      ) {
        formIsValid = false
      }
    }
    this.setState({ formData: newFormData, formIsValid })
  }

  handleBlur(key) {
    const { formData } = this.state
    const newFormData = {
      ...formData,
    }
    if (!formData[key].valid) {
      newFormData[key].error = true
      this.setState({ formData: newFormData })
    } else {
      newFormData[key].error = false
      this.setState({ formData: newFormData })
    }
  }

  handleReset(e) {
    e.preventDefault()
    this.setState({ submitFailed: false })
  }

  render() {
    const {
      formData,
      formIsValid,
      reCaptchaPassed,
      loading,
      succes,
      submitFailed,
    } = this.state
    const { email, name, phone, subject, message } = formData
    const { locale, companyEmail } = this.props
    const submitEnabled = formIsValid && reCaptchaPassed

    let form = (
      <form onSubmit={this.handleSubmit}>
        <InputContainer>
          <TextField
            id="name"
            name="name"
            variant="outlined"
            margin="dense"
            label="Name"
            fullWidth
            value={name.value}
            onChange={(e) => this.handleChange(e, "name")}
            onBlur={() => this.handleBlur("name")}
            error={name.error}
            valid={name.valid.toString()}
          />
          <ErrorMessage error={name.error} errorMessage={name.errorMessage} />
        </InputContainer>
        <InputContainer>
          <TextField
            id="email"
            name="email"
            variant="outlined"
            margin="dense"
            label="Email"
            fullWidth
            value={email.value}
            onChange={(e) => this.handleChange(e, "email")}
            onBlur={() => this.handleBlur("email")}
            error={email.error}
            valid={email.valid.toString()}
          />
          <ErrorMessage error={email.error} errorMessage={email.errorMessage} />
        </InputContainer>
        <InputContainer>
          <OptionalFormText>{localeData._rawOptional[locale]}</OptionalFormText>
          <TextField
            id="phone"
            name="phone"
            variant="outlined"
            margin="dense"
            type="tel"
            label="Phone"
            fullWidth
            value={phone.value}
            onChange={(e) => this.handleChange(e, "phone")}
            onBlur={() => this.handleBlur("phone")}
          />
        </InputContainer>
        <InputContainer>
          <OptionalFormText>{localeData._rawOptional[locale]}</OptionalFormText>
          <TextField
            id="subject"
            name="subject"
            variant="outlined"
            margin="dense"
            label="Subject"
            fullWidth
            value={subject.value}
            onChange={(e) => this.handleChange(e, "subject")}
            onBlur={() => this.handleBlur("subject")}
          />
        </InputContainer>
        <InputContainer>
          <TextField
            id="message"
            name="message"
            variant="outlined"
            margin="dense"
            label="Message"
            multiline
            rows="6"
            rowsMax="10"
            fullWidth
            value={message.value}
            onChange={(e) => this.handleChange(e, "message")}
            onBlur={() => this.handleBlur("message")}
            error={message.error}
            valid={message.valid.toString()}
          />
          <ErrorMessage
            error={message.error}
            errorMessage={message.errorMessage}
          />
        </InputContainer>
        <Vspacer marginBottom={vSpacing.baseX6}>
          <Reaptcha
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
            onVerify={this.handleReCAPTCHAValid}
            hl={locale}
          />
        </Vspacer>
        <PrimaryBtn type="submit" disabled={!submitEnabled}>
          {localeData._rawSendBtnText[locale]}
        </PrimaryBtn>
      </form>
    )

    if (loading) {
      form = <Loader />
    }
    if (succes) {
      form = (
        <SuccesBackground>
          <p>{localeData._rawThankYouMessage[locale]}</p>
        </SuccesBackground>
      )
    }
    if (submitFailed) {
      form = (
        <div>
          <p>
            {localeData._rawFailedMessage[locale]}{" "}
            <a href={`mailto:${companyEmail}`}>{companyEmail}</a>{" "}
          </p>
          <PrimaryBtn onClick={(e) => this.handleReset(e)}>
            {localeData._rawTryAgain[locale]}
          </PrimaryBtn>
        </div>
      )
    }
    return <React.Fragment>{form}</React.Fragment>
  }
}

export default ContactForm
