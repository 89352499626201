import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const ShowDesktopOnly = styled.div`
  display: none;
  ${breakpoints.md`
    display: block;
  `};
`;

export default ShowDesktopOnly;
